import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import FavoriteTwoToneIcon from "@mui/icons-material/FavoriteTwoTone";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import FontSizeChanger from "react-font-size-changer";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import alertify from "alertifyjs";
import { useCookies } from "react-cookie";
import "alertifyjs/build/css/alertify.css";
import BarTop from "../components/BarTop";
import BarBottom from "../components/BarBottom";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
let enableNewUI = process.env.REACT_APP_NEWUI_LW;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const backgroundColor = process.env.REACT_APP_BUTTON_COLOR;
const logoWidth = process.env.REACT_APP_LOGO_WIDTH;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;
const hoverColor = process.env.REACT_APP_TEXT_HIGHLIGHT;

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      <br></br>
      Made with <FavoriteTwoToneIcon sx={{ color: "#820813" }} /> by<br></br>
      <Link color="inherit" target="_blank" href="https://servingintel.com/">
        <img
          className="silogo"
          src={siteMedSrc + "si-logo" + ".svg"}
          alt="ServingIntel"
        />
        <br></br>
        <br></br>
      </Link>{" "}
    </Typography>
  );
}

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "360px",
  backgroundColor: "transparent !important",
  boxShadow: "none !important",
  textTransform: "none",
});

const theme = createTheme();

export const UpdateCode = () => {
  const history = useHistory();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };

  const [newFontSize, setFontSize] = useState(16);
  const increaseFont = () => setFontSize(newFontSize + 2);

  const firstName = sessionStorage.getItem("firstName");
  const roomNumber = sessionStorage.getItem("roomNumber");

  const [pinCode, setPinCode] = useState();
  const [pinCodeConfirm, setPinCodeConfirm] = useState();
  const location = useLocation();
  const [token, setToken] = useState();

  const [pinVisible, setPINVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [layoutPIN, setlayoutPIN] = useState("default");
  const [layoutConfirm, setlayoutConfirm] = useState("default");
  const pinInputRef = useRef(null);
  const confirmInputRef = useRef(null);

  // Function to handle clicks outside the TextField and Keyboard
  const handleClickOutside = (event) => {
    if (pinInputRef.current && !pinInputRef.current.contains(event.target)) {
      setPINVisible(false);
    }
    if (
      confirmInputRef.current &&
      !confirmInputRef.current.contains(event.target)
    ) {
      setConfirmVisible(false);
    }
  };

  useEffect(() => {
    // Add event listener to handle clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOnPINPress = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      handleShiftPIN();
    } else if (button === "{enter}") {
      setPINVisible(false);
    } else if (button === "{bksp}") {
      setPinCode((prev) => prev.slice(0, -1));
    } else {
      setPinCode((prev) => prev + button);
    }
  };

  const handleOnConfirmPress = (button) => {
    if (button === "{shift}" || button === "{lock}") {
      handleShiftConfirm();
    } else if (button === "{enter}") {
      setConfirmVisible(false);
    } else if (button === "{bksp}") {
      setPinCodeConfirm((prev) => prev.slice(0, -1));
    } else {
      setPinCodeConfirm((prev) => prev + button);
    }
  };

  const handleShiftPIN = () => {
    setlayoutPIN(layoutPIN === "default" ? "shift" : "default");
  };

  const handleShiftConfirm = () => {
    setlayoutConfirm(layoutConfirm === "default" ? "shift" : "default");
  };

  const handlePINChange = (input) => {
    setPinCode(input);
    // Refocus the TextField after each keyboard input
    if (pinInputRef.current) {
      pinInputRef.current.querySelector("input").focus();
    }
  };

  const handleConfirmChange = (input) => {
    setPinCodeConfirm(input);
    // Refocus the TextField after each keyboard input
    if (confirmInputRef.current) {
      confirmInputRef.current.querySelector("input").focus();
    }
  };

  const numpadLayout = {
    default: [
      "1 2 3 4 5 6 7 8 9 0",
      "q w e r t y u i o p {bksp}",
      "a s d f g h j k l {enter}",
      "{shift} z x c v b n m , . {shift}",
      "{space}",
    ],
    shift: [
      "1 2 3 4 5 6 7 8 9 0",
      "Q W E R T Y U I O P {bksp}",
      "A S D F G H J K L {enter}",
      "{shift} Z X C V B N M , . {shift}",
      "{space}",
    ],
  };

  const handleFocusPIN = () => {
    setPINVisible(true);
    setTimeout(() => {
      pinInputRef.current.querySelector("input").focus(); // Focus the input field
    }, 0);
  };

  const handleFocusConfirm = () => {
    setConfirmVisible(true);
    setTimeout(() => {
      confirmInputRef.current.querySelector("input").focus(); // Focus the input field
    }, 0);
  };

  const [cookie, setCookie, removeCookie] = useCookies();

  const validateAccount = () => {
    const url =
      "https://ecommv2.servingintel.com/customer/save-pin/" +
      serverId +
      "/" +
      siteId +
      "/" +
      storeId +
      "/" +
      firstName +
      "/" +
      roomNumber +
      "/" +
      pinCode;

    if (!pinCode || !pinCodeConfirm) {
      alertify.alert("Error", "Please Enter your PIN Code", function () {});
      return;
    }
    if (String(pinCode) === String(pinCodeConfirm)) {
      axios.get(url).then((response) => {
        setCookie("firstName", firstName, { path: "/" });
        setCookie("roomNumber", roomNumber, { path: "/" });
        history.push("/LoginCode");
      });
    } else {
      alertify.alert("Error", "PIN code did not match", function () {});
      return;
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          justifyContent={enableKiosk === "1" ? "center" : null}
          alignItems={enableKiosk === "1" ? "center" : null}
          sx={{
            fontSize: enableKiosk === "0" ? `${newFontSize}px` : null,
            backgroundImage:
              enableKiosk === "1" ? `url(${siteMedSrc}signbg.png)` : null,
            backgroundRepeat: enableKiosk === "1" ? "no-repeat" : "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: enableKiosk === "1" ? "cover" : "cover",
            backgroundPosition: enableKiosk === "1" ? "left" : "left",
            left: enableKiosk === "1" ? "0" : "0",
            right: enableKiosk === "1" ? "0" : "0",
            minHeight: "100vh",
            height: enableKiosk === "0" ? "100vh" : null,
          }}
        >
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={enableKiosk === "1" ? false : 4}
            md={enableKiosk === "1" ? false : 7}
            sx={{
              backgroundRepeat: enableKiosk === "1" ? "no-repeat" : "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: enableKiosk === "1" ? "cover" : "cover",
              backgroundPosition: enableKiosk === "1" ? "left" : "left",
              left: enableKiosk === "1" ? "0" : "0",
              right: enableKiosk === "1" ? "0" : "0",
              backgroundImage:
                enableKiosk === "0" ? `url(${siteMedSrc}signbg.png)` : null,
            }}
          />

          <Grid
            item
            xs={enableKiosk === "1" ? 10 : 12}
            sm={enableKiosk === "1" ? 10 : 8}
            md={enableKiosk === "1" ? 10 : 5}
            justifyContent="center"
            alignItems="center"
            component={Paper}
            elevation={6}
            square
          >
            <Box
              id="target"
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {enableNewUI == 1 ? (
                <img
                  src={siteMedSrc + "logo" + ".png"}
                  className="SL-logo02"
                  alt={`${restaurantName} logo`}
                />
              ) : enableNewUI == 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={siteMedSrc + "logo" + ".png"}
                    width={logoWidth}
                    alt="logo"
                    style={{
                      transform: "scale(1.75)",
                      paddingTop: ".5rem",
                      paddingBottom: "1rem",
                    }}
                  />
                </div>
              ) : (
                // Add a default case here, for example:
                <img
                  src={siteMedSrc + "logo" + ".png"}
                  className="Default-logo"
                  alt={`${restaurantName} logo`}
                />
              )}
              <br />
              <Typography
                className="content"
                sx={{
                  marginTop: 1,
                  textAlign: "center",
                  lineHeight: 1.1,
                  fontWeight: "bold",
                  fontSize:
                    enableKiosk === "0"
                      ? "1.5rem !important"
                      : "1.75rem !important",
                }}
              >
                Please create your PIN (4 Digit)
              </Typography>
              <br />

              <Box
                className="content"
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: -2 }}
              >
                <div ref={pinInputRef}>
                  <TextField
                    margin="normal"
                    type="text"
                    required
                    fullWidth
                    name="pinCode"
                    label="Enter PIN"
                    id="pinCode"
                    onFocus={handleFocusPIN}
                    inputProps={{ maxLength: 4 }}
                    variant="standard"
                    value={pinCode}
                    InputLabelProps={{
                      style: {
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.75rem",
                        marginTop: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                      },
                    }}
                    sx={{ pt: 1.5 }}
                    onChange={(e) => setPinCode(e.target.value)}
                  />
                  {pinVisible && parseInt(enableKiosk) === 1 && (
                    <Keyboard
                      className="eKioskInput"
                      layout={numpadLayout}
                      layoutName={layoutPIN}
                      onChange={handlePINChange}
                      onKeyPress={handleOnPINPress}
                      inputName="pinCode"
                    />
                  )}
                </div>
                <div ref={confirmInputRef}>
                  <TextField
                    margin="normal"
                    type="text"
                    required
                    fullWidth
                    name="pinCodeConfirm"
                    label="Re-Enter PIN"
                    id="pinCodeConfirm"
                    onFocus={handleFocusConfirm}
                    inputProps={{ maxLength: 4 }}
                    variant="standard"
                    value={pinCodeConfirm}
                    InputLabelProps={{
                      style: {
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.5rem",
                      },
                    }}
                    InputProps={{
                      style: {
                        fontSize: enableKiosk === "0" ? "1.25rem" : "1.75rem",
                        marginTop: enableKiosk === "0" ? "1.125rem" : "1.5rem",
                      },
                    }}
                    sx={{ pt: 1.5 }}
                    onChange={(e) => setPinCodeConfirm(e.target.value)}
                  />
                  {confirmVisible && parseInt(enableKiosk) === 1 && (
                    <Keyboard
                      className="eKioskInput"
                      layout={numpadLayout}
                      layoutName={layoutConfirm}
                      onChange={handleConfirmChange}
                      onKeyPress={handleOnConfirmPress}
                      inputName="pinCodeConfirm"
                    />
                  )}
                </div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 5,
                    mb: 2,
                    fontWeight: "600",
                    backgroundColor: buttonColor,
                    "&:hover": {
                      backgroundColor: hoverColor,
                    },
                  }}
                  onClick={() => {
                    validateAccount();
                  }}
                >
                  <Typography
                    className="content"
                    style={{
                      fontSize: enableKiosk === "0" ? "1.25rem" : "1.75rem",
                      fontWeight: "600",
                    }}
                  >
                    LOGIN
                  </Typography>
                </Button>
              </Box>
            </Box>

            {enableNewUI === "0" && enableKiosk === "0" ? (
              <Copyright
                id="copyrignt"
                position="centered"
                sx={{ top: "auto", bottom: 0 }}
              />
            ) : null}
            {enableKiosk === "0" ? (
              <FontSizeChanger
                targets={["#target .content"]}
                onChange={(element, newValue, oldValue) => {
                  console.log(element, newValue, oldValue);
                }}
                options={{
                  stepSize: 2,
                  range: 5,
                }}
                customButtons={{
                  up: (
                    <AppBar
                      position="fixed"
                      sx={{
                        width: "90px",
                        boxShadow: 0,
                        top: "auto",
                        bottom: 237.5,
                        backgroundColor: "transparent",
                      }}
                    >
                      <Toolbar>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton aria-label="add font size">
                          <Fab
                            color="primary"
                            aria-hidden="true"
                            sx={{ backgroundColor: "#3D5309" }}
                          >
                            <AddCircleOutlineIcon />
                          </Fab>
                        </IconButton>
                      </Toolbar>
                    </AppBar>
                  ),
                  down: (
                    <AppBar
                      position="fixed"
                      sx={{
                        width: "90px",
                        boxShadow: 0,
                        top: "auto",
                        bottom: 180,
                        backgroundColor: "transparent",
                      }}
                    >
                      <Toolbar>
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton>
                          <Fab
                            color="primary"
                            aria-hidden="true"
                            sx={{ backgroundColor: "#765F00" }}
                          >
                            <RemoveCircleOutlineIcon />
                          </Fab>
                        </IconButton>
                      </Toolbar>
                    </AppBar>
                  ),
                }}
              />
            ) : null}
          </Grid>
        </Grid>
        {enableNewUI === "1" ? (
          <>
            <BarTop />
            <BarBottom />
          </>
        ) : null}
      </ThemeProvider>
    </>
  );
};
